<template>
  <div class="consortium">
    <div class="top_Img">
      <img src="../../assets/banner/metting.jpg" alt="">
      <div class="search"><a-input-search
        placeholder="请输入关键字"
        enter-button="搜索"
        size="large"
        @search="onSearch"
        v-model="searchTitle"
        style="background:#fbc914;"
      /></div>
    </div>
    <!-- 搜索条件 -->
    <div class="SearchItems">
      <div class="Itemlists" v-for="(item,index) of searchItemData" :key="index">
        <span class="name">{{item.searchTitle}}：</span><span @click="handleCheck(index,v,i)" :class="i.ischeck ? 'Ischecked' : ''" style="cursor:pointer;" v-for="(i,v) of item.lists" :key="v">{{i.name}}</span>
      </div>
    </div>
    <!-- 数据展示 -->
    <div class="items" v-if="DataList.length != 0">
      <div class="list" @click="handleDetails(item)" v-for="(item,index) of DataList" :key="index">
        <div class="Img">
          <img :src="item.cover" alt="">
        </div>
        <div class="message">
          <div class="title1">{{item.title}}</div>
          <div class="title2" v-html="item.content">{{item.content}}</div>
          <div class="time">{{moment(item.create_time).format('YYYY-MM-DD')}}</div>
        </div>
      </div>
    </div>
    <div v-else style="text-align:center;margin-top:20px;margin-bottom:20px;">
      <img src="../../assets/nodata.jpg" alt="">
      <div>暂无文章</div>
    </div>
    <!-- 分页 -->
    <div class="pagination" v-if="DataList.length != 0">
      <a-pagination :default-current="1" :total="total" @change="changePage" />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data(){
    return {
      moment,
      searchItemData:[
        // {searchTitle:'联合体活动',lists:[
        //   {name:'全部',ischeck:true},{name:'类目一',ischeck:false},{name:'类目二',ischeck:false},{name:'类目三',ischeck:false},{name:'类目四',ischeck:false},{name:'类目五',ischeck:false},
        //   {name:'类目六',ischeck:false},{name:'类目七',ischeck:false},{name:'类目八',ischeck:false},{name:'类目九',ischeck:false},{name:'类目十',ischeck:false}
        //   ]},
        // {searchTitle:'联合体项目',lists:[
        //   {name:'全部',ischeck:true},{name:'类目一',ischeck:false},{name:'类目二',ischeck:false},{name:'类目三',ischeck:false},{name:'类目四',ischeck:false},{name:'类目五',ischeck:false},
        //   {name:'类目六',ischeck:false},{name:'类目七',ischeck:false},{name:'类目八',ischeck:false},{name:'类目九',ischeck:false},{name:'类目十',ischeck:false}
        // ]},
        // {searchTitle:'科学宣传',lists:[
        //   {name:'全部',ischeck:true},{name:'类目一',ischeck:false},{name:'类目二',ischeck:false},{name:'类目三',ischeck:false}
        // ]},
        // {searchTitle:'科学人物',lists:[
        //   {name:'全部',ischeck:true},{name:'类目一',ischeck:false},{name:'类目二',ischeck:false},{name:'类目三',ischeck:false}
        // ]}
      ],
      total:1,
      searchTitle:'',
      searchData:{
        kw:'',
        page_index:1,
        per_page_count:10,
        querys:[],
      },
      DataList:[],
    }
  },
  methods:{
    // 页码查询
    changePage(page,pageSize){
      this.searchData.page_index = page;
      window.scrollTo(0,0)
      this.SearchNews();
    },
    // 查看详情
    handleDetails(data){
      this.$router.push({path:'messageDetails',query:{isTop:'Top',detailID:data.id}});
    },
    onSearch(){
      this.SearchNews();
    },
    // 选择查询
    handleCheck(index,indexs,data){
      this.searchData.querys = [];
      this.searchItemData[index].lists[indexs].ischeck = !data.ischeck;
      if(data.name == '全部'){
        this.searchItemData[index].lists.forEach(item=>{
          if(item.name != '全部'){
            item.ischeck = false;
          }
        })
      }else{
        this.searchItemData[index].lists.forEach(item=>{
          if(item.name == '全部'){
            item.ischeck = false;
          }
        })
      }
      this.searchItemData.forEach(item=>{
        let querys = {child_id:[]};
        querys.column_id = item.id;
        item.lists.forEach((item2,i)=>{
          if(item2.name == '全部'){
            if(item2.ischeck){ // 全部勾选
              item.lists.forEach(k=>{
                if(k.name != '全部'){
                  querys.child_id.push(k.id);
                }
              })
            }else{ // 取消全部勾选
              // this.searchData.querys.forEach((item,i)=>{
              //   if(item.column_id == item2.id){
              //     this.searchData.querys.splice(i,1);
              //   }
              // })
            }
          }else{
            if(item2.ischeck){
              querys.child_id.push(item2.id);
            }
          }
        })
        this.searchData.querys.push(querys);
      })
      this.SearchNews();
    },
    // 关键字查询新闻数据
    SearchNews(){
      this.searchData.kw = this.searchTitle;
      this.$post('/v1/news/search/kw',this.searchData).then(res=>{
        res.data.forEach(item=>{
          if(item.content){
            item.content = item.content.replace(/<\/?(img)[^>]*>/gi, '');
          }
        })
        this.DataList = res.data;
        this.total = res.count;
      })
    },
    // 获取导航数据做分类数据展示
    getSearchlists(){
      this.$get('/v1/column/all').then(data=>{
        data.forEach(item=>{
        if(item.type != '-1' && item.title !='关于联合体'){
          let datajson = {lists:[
            // {name:'全部',ischeck:true,id:item.id}
            ]};
          let querys = {child_id:[]};
          querys.column_id = item.id;
          datajson.searchTitle = item.title;
          datajson.id = item.id;
          if(item.child_column.length != 0){
            item.child_column.forEach((i,v)=>{
              let datajson2 = {};
              querys.child_id.push(i.id);
              datajson2.name = i.title;
              datajson2.id = i.id;
              datajson2.ischeck = false;
              datajson.lists.push(datajson2);
            })
          }
          this.searchData.querys.push(querys);
          this.searchItemData.push(datajson);
          }
        })
        this.SearchNews();
      })
    },
  },
  mounted(){
    let query = this.$route.query;
    this.searchTitle = query.searchName;
    this.getSearchlists();
  },
}
</script>

<style lang="scss" scoped>
  ::v-deep .ant-pagination-item-active a{color: #fff;}
  ::v-deep .ant-btn-primary{background: #fbc914;border-color: #fbc914;}
  .consortium{
    .top_Img{position: relative;
      img{width: 100%;max-width: 100%;height: 100%;}
      .search{position: absolute;top:55%;left: calc(50% - 225px);font-size: 40px;font-weight: bold;color: #fff;width: 550px;}
    }
    .SearchItems{max-width: 1400px;margin: 15px auto;border: 1px solid #ececec;padding: 0 15px;font-size:14px;
      .Itemlists{line-height: 50px;border-bottom: 1px solid #ececec;
        .name{display: inline-block;text-align: right;width: 100px;}
        span{display: inline-block;margin: 0 10px;line-height: 21px;padding: 2px 5px;}
        .Ischecked{background: #fbc914;color: #fff;border-radius: 4px;}
      }
      .Itemlists:last-child{border-bottom: 0;}
    }
    .items{padding: 12px;margin:0 auto;margin-top:20px;max-width: 1400px;
      .list{display: flex;border-bottom:1px solid #ececec;margin-bottom:15px;padding-bottom:15px;height:146px;cursor: pointer;
        img{width: 200px;height: 130px;}
        .message{margin-left: 30px;display: flex;flex-direction: column;justify-content: space-between;
          
        }
      }
    }
    .title1{font-size: 20px;font-weight: bold;color: #333;}
      .title2,.time{font-size: 12px;color: #333;}
    .pagination{max-width: 1400px;margin: 0 auto;display: flex;justify-content: flex-end;}
  }
</style>